import structuredClone from '@ungap/structured-clone';
import { Logger } from '@getvim-os/logger';
import { OsCommunicator } from '..';
import { Subscription } from '../subscription';
import {
  UpdatableEhrFields,
  EHRResource,
  OSToAppMessageEvent,
  OSToAppMessageTypes,
} from '@getvim/vim-os-api';
import EncounterUpdateBuilder from './encounterUpdateBuilder';
import ReferralUpdateBuilder from './referralUpdateBuilder';
import { IInternalResourceUpdater } from '../../types/ehr-updates/builders';
import { EHR } from '@getvim/vim-os-sdk-api';

export class InternalResourceUpdater
  extends Subscription<{ updatableFields: UpdatableEhrFields }>
  implements IInternalResourceUpdater
{
  #updatableFields: UpdatableEhrFields = {};
  #ehrState: EHR.EHR_STATE_RESOURCES<any> = {};
  #ehrUpdatableFields: UpdatableEhrFields = {};
  get updatableFields() {
    return this.#updatableFields;
  }

  constructor(
    incomingOsMessagePort: MessagePort,
    private osCommunicator: OsCommunicator,
    private logger?: Logger,
  ) {
    super({ updatableFields: {} });
    incomingOsMessagePort.addEventListener('message', (message: OSToAppMessageEvent) => {
      if (message.data.type === OSToAppMessageTypes.EHR_STATE_CHANGE) {
        const { updatableFields, ehrUpdatableFields, ...ehrState } = message.data.payload;
        this.#ehrState = ehrState;
        this.#updatableFields = updatableFields || {};
        this.#ehrUpdatableFields = ehrUpdatableFields || {};
        this.dispatch('updatableFields', this.#updatableFields);
      }
    });
  }

  public encounterBuilder() {
    return new EncounterUpdateBuilder(
      this.osCommunicator,
      structuredClone(this.#updatableFields[EHRResource.encounter]),
      structuredClone(this.#ehrUpdatableFields[EHRResource.encounter]),
      structuredClone(this.#ehrState),
      this.logger,
    );
  }

  public referralBuilder() {
    return new ReferralUpdateBuilder(
      this.osCommunicator,
      structuredClone(this.#updatableFields[EHRResource.referral]),
      structuredClone(this.#ehrUpdatableFields[EHRResource.referral]),
      structuredClone(this.#ehrState),
      this.logger,
    );
  }
}
